import React, { useState } from "react";
import styled from "styled-components";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import P from "../assets/8.png";
import Off33 from "../assets/5.png";

// 샘플 이미지와 텍스트 데이터
const products = [
  {
    src: P,
    name: "센스티브 스킨 리커버리 플라스터 팩",
    discount: "NEW",
    link: "https://smartstore.naver.com/tropicalmood/products/10509089276",
  },
  {
    src: Off33,
    name: "센스티브 스킨 리커버리 플라스터 팩 런칭 이벤트!",
    discount: "33% OFF",
    link: "https://smartstore.naver.com/tropicalmood/products/10505677724",
  },
];

const SectionWrapper = styled.section`
  width: 80%;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const TextSection = styled.div`
  flex: 1;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const Title = styled.h1`
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    color: blue;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ProductsGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2;
  position: relative;
`;

const ProductsGrid = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProductCard = styled.div`
  flex: 0 0 33.333%;
  box-sizing: border-box;
  padding: 10px;
  position: relative;

  &:hover .overlay {
    opacity: 0.5;
  }

  &:hover .product-name {
    opacity: 1;
  }

  @media (max-width: 768px) {
    flex: 1 1 100%;
    padding: 5px;
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const DiscountBadge = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const ProductName = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
`;

// const Arrow = styled.div`
//   display: block;
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   font-size: 2rem;
//   color: black;
//   cursor: pointer;
//   z-index: 2;

//   &.left {
//     left: 30px;
//   }

//   &.right {
//     right: 30px;
//   }

//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

const MoreButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  background-color: black;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: gray;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    width: 70%;
    text-align: center;
  }
`;

// const Navigation = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-top: 20px;
// `;

// const NavDot = styled.div`
//   width: 10px;
//   height: 10px;
//   background-color: ${({ active }) => (active ? "black" : "gray")};
//   border-radius: 50%;
//   margin: 0 5px;
//   cursor: pointer;
// `;

const ImgSection = styled.div`
  width: 70%;
  display: block;
  margin: 0 auto;
`;

const ProductSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // // const handlePrev = () => {
  // //   setCurrentIndex((prevIndex) =>
  // //     prevIndex === 0 ? Math.ceil(products.length / 3) - 1 : prevIndex - 1
  // //   );
  // // };

  // // const handleNext = () => {
  // //   setCurrentIndex((prevIndex) =>
  // //     prevIndex === Math.ceil(products.length / 3) - 1 ? 0 : prevIndex + 1
  // //   );
  // // };

  return (
    <SectionWrapper>
      <TextSection>
        <Title>Products</Title>
        {/* <Subtitle>신상품</Subtitle>
        <Subtitle>베스트</Subtitle> */}
      </TextSection>
      <ImgSection>
        <ProductsGridWrapper>
          {/* <Arrow className="left" onClick={handlePrev}>
            <FaArrowLeft />
          </Arrow> */}
          <ProductsGrid
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {products.map((product, index) => (
              <ProductCard key={index}>
                <ProductImage src={product.src} alt={product.name} />
                <DiscountBadge>{product.discount}</DiscountBadge>
                <Overlay className="overlay" />
                <ProductName
                  className="product-name"
                  onClick={() => (window.location.href = product.link)}
                >
                  {product.name}
                </ProductName>
              </ProductCard>
            ))}
          </ProductsGrid>
          <MoreButton href="https://smartstore.naver.com/tropicalmood">
            View all
          </MoreButton>
          {/* <Arrow className="right" onClick={handleNext}>
            <FaArrowRight />
          </Arrow> */}
        </ProductsGridWrapper>
        <>
          {/* <Navigation>
            {[...Array(Math.ceil(products.length / 3))].map((_, index) => (
              <NavDot
                key={index}
                active={index === currentIndex}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </Navigation> */}
        </>
      </ImgSection>
    </SectionWrapper>
  );
};

export default ProductSection;
