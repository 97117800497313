import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Img1 from "../assets/1.png";
import Img2 from "../assets/2.png";
import Img3 from "../assets/3.png";
import Img4 from "../assets/4.png";
// import Img5 from "../assets/3-1.jpg";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// 샘플 이미지와 텍스트 데이터
const data = [
  {
    image: Img1,
    tag: "#피부보습 #피부진정 #마스크스틱",
    title: "5가지 식물유례성분!\n",
    subtitle: "피부를 채워 보습과 진정시켜줍니다.",
    url: "https://example.com/product4",
  },
  {
    image: Img2,
    tag: "#피부진정 #피부보습 #피부미백 #피지조절",
    title: "트로피칼 무드\n올인원 마스크스틱",
    subtitle: "센스티브 스킨 리커버리 석고팩!",
    url: "https://example.com/product4",
  },
  {
    image: Img3,
    tag: "#피지조절 #모공청결 #민감피부",
    title: "카올린의 피지 조절",
    subtitle: "탁월한 흡수력으로 모공을 청결하게!",
    url: "https://example.com/product4",
  },
  {
    image: Img4,
    tag: "#피부진정 #수분가득 #염증완화",
    title: "아줄렌의 피부 진정",
    subtitle: "피부 염증을 줄이고 붉어짐과 자극 완화!",
    url: "https://example.com/product4",
  },
  // {
  //   image: Img5,
  //   tag: "#피부보습 #피부영양 #미백주름개선 #이중기능성",
  //   title: "트로피칼 무드 \n이중기능성 3종 세트",
  //   subtitle: "피부속부터 즉각적인 수분가득 영양공급!",
  //   url: "https://example.com/product4",
  // },
];

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const BannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 1s ease;

  @media (max-width: 768px) {
    height: 100vh;
    flex-direction: column;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  ${({ direction }) => (direction === "left" ? "left: 7%;" : "right: 7%;")}
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 2.5rem;
  color: white;
  z-index: 10;

  @media (max-width: 768px) {
    top: auto;
    bottom: 20px;
    ${({ direction }) =>
      direction === "left" ? "left: 40px;" : "right: 40px;"}
    font-size: 1rem;
  }
`;

const BannerContent = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  text-align: left;
  z-index: 5;
  animation: ${fadeIn} 1s ease;
  margin-left: 50px;

  @media (max-width: 768px) {
    width: 90%;
    align-items: left;
    text-align: left;
    margin-left: 0;
  }
`;

const BannerTag = styled.p`
  font-size: 1.5rem;
  margin-bottom: 10px;
  animation: ${fadeIn} 1s ease;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const BannerTitle = styled.h1`
  font-size: 5rem;
  margin: 0;
  animation: ${fadeIn} 1s ease;
  white-space: pre-line;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const BannerSubtitle = styled.p`
  font-size: 2rem;
  margin: 0;
  padding-bottom: 30px;
  animation: ${fadeIn} 1s ease;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  animation: ${fadeIn} 1s ease;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PageIndicator = styled.div`
  position: absolute;
  bottom: 10px;
  font-size: 1.2rem;
  color: white;
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 10;

  @media (max-width: 768px) {
    bottom: 25px;
    font-size: 1rem;
  }
`;

const Banner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handleButtonClick = () => {
    window.location.href = data[currentIndex].url;
  };
  return (
    <BannerWrapper
      style={{ backgroundImage: `url(${data[currentIndex].image})` }}
    >
      <Arrow direction="left" onClick={handlePrev}>
        <FaArrowLeft />
      </Arrow>

      <BannerContent key={currentIndex}>
        <BannerTag>{data[currentIndex].tag}</BannerTag>
        <BannerTitle>{data[currentIndex].title}</BannerTitle>
        <BannerSubtitle>{data[currentIndex].subtitle}</BannerSubtitle>
        <Button onClick={handleButtonClick}>MORE</Button>
      </BannerContent>

      <Arrow direction="right" onClick={handleNext}>
        <FaArrowRight />
      </Arrow>

      <PageIndicator>
        {currentIndex + 1} / {data.length}
      </PageIndicator>
    </BannerWrapper>
  );
};

export default Banner;
