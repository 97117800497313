import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Img1 from "../assets/11.png";
import Img2 from "../assets/22.png";
// import Img3 from "../assets/2-2.jpg";
// import Img4 from "../assets/2-3.jpg";

// 페이드 인 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BannerSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  padding: 0 50px;
  background-image: url("../assets/33.png");
  background-size: cover;
  background-position: center;
  color: white;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 768px) {
    width: auto;
    height: 50%;
    padding: 20px;
  }
`;

const ProductSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 0 50px;
  background-color: white;
  position: relative;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 768px) {
    width: auto;
    height: 50%;
    padding: 20px;
  }

  &:hover .arrow {
    display: block;
  }
`;

const BannerText = styled.h1`
  font-size: 5rem;
  line-height: 1.2;
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 768px) {
    font-size: 2rem;
    color: black;
  }
`;

const BannerSubtitle = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 768px) {
    font-size: 1rem;
    color: black;
  }
`;

const ProductTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: black;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ProductImageWrapper = styled.div`
  width: 80%;
  margin-bottom: 20px;
  position: relative;
  animation: ${fadeIn} 1s ease-out;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  &:hover .product-name {
    display: block;
  }
`;

const DiscountBadge = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
`;

const PageIndicator = styled.div`
  font-size: 1rem;
  color: black;
  margin-top: 10px;
  animation: ${fadeIn} 1s ease-out;
  transition: opacity 0.5s ease-in-out;
`;

const Arrow = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  ${({ direction }) => (direction === "left" ? "left: 50px;" : "right: 50px;")}
  transform: translateY(-50%);
  font-size: 2rem;
  color: black;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 768px) {
    ${({ direction }) =>
      direction === "left" ? "left: 15px;" : "right: 15px;"}
    font-size: 1.5rem;
  }
`;

const ProductName = styled.div`
  display: none;
  position: absolute;
  bottom: 10px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  animation: ${fadeIn} 0s ease-out;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const images = [
  {
    src: Img1,
    name: "Sensitive Skin Recovery Plaster Pack 33% Off",
    link: "https://smartstore.naver.com/tropicalmood/products/10505677724",
  },
  {
    src: Img2,
    name: "Sensitive Skin Recovery Plaster Pack 33% Off",
    link: "https://smartstore.naver.com/tropicalmood/products/10505677724",
  },
  // { src: Img3, name: "Product 3", link: "https://example.com/product3" },
  // { src: Img4, name: "Product 4", link: "https://example.com/product4" },
];

const Section2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  const handlePrev = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    }, 500);
  };

  const handleNext = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 500);
  };

  const handleProductClick = (link) => {
    window.location.href = link;
  };

  return (
    <SectionWrapper>
      <BannerSection>
        <BannerText>
          바쁜일상에 <br /> #쉼표하나
        </BannerText>
        <BannerSubtitle>기본에 충실한 생활속의 피부케어</BannerSubtitle>
        <BannerSubtitle>트로피칼무드 스틱팩</BannerSubtitle>
      </BannerSection>
      <ProductSection>
        <ProductTitle>Signature Launching Event</ProductTitle>
        <ProductImageWrapper
          style={{
            transform: isTransitioning ? "scale(0.95)" : "scale(1)",
            opacity: isTransitioning ? 0 : 1,
          }}
        >
          <img src={images[currentIndex].src} alt="Product" />
          <DiscountBadge>33% OFF</DiscountBadge>
          <ProductName
            className="product-name"
            onClick={() => handleProductClick(images[currentIndex].link)}
          >
            {images[currentIndex].name}
          </ProductName>
        </ProductImageWrapper>
        <Arrow className="arrow" direction="left" onClick={handlePrev}>
          <FaArrowLeft />
        </Arrow>
        <PageIndicator
          style={{
            opacity: isTransitioning ? 0 : 1,
          }}
        >
          {currentIndex + 1} / {images.length}
        </PageIndicator>
        <Arrow className="arrow" direction="right" onClick={handleNext}>
          <FaArrowRight />
        </Arrow>
      </ProductSection>
    </SectionWrapper>
  );
};

export default Section2;
