import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const AppContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  video {
    object-fit: cover !important;
    width: 100%;
    height: 100%;
  }
`;

const MoreButton = styled.a`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  text-decoration: none;
  font-size: 20px;
  border-radius: 5px;
  z-index: 1;
`;

function VideoSection() {
  return (
    <AppContainer>
      <VideoWrapper>
        <StyledReactPlayer
          url="./videos/1.mp4"
          playing
          width="100%"
          height="100%"
          controls={false}
          loop
          muted
          playsinline
        />
        <MoreButton href="https://smartstore.naver.com/tropicalmood/products/10505677724">
          More
        </MoreButton>
      </VideoWrapper>
    </AppContainer>
  );
}

export default VideoSection;
