import React, { useState } from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 100px;
  justify-content: space-between;
  height: 60px;
  background-color: transparent;
  color: ${(props) => (props.isDark ? "white" : "black")};
  position: fixed;
  top: 20px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    left: 30px;
    position: absolute;
  }
`;

const LogoLink = styled.a`
  color: ${(props) => (props.isDark ? "white" : "black")};
  text-decoration-line: none;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.a`
  margin-left: 20px;
  text-decoration: none;
  color: inherit;
  font-size: 18px;

  &:hover {
    color: #ddd;
  }
`;

const Hamburger = styled.div`
  display: none;
  font-size: 24px;
  position: absolute;
  right: 20px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileNav = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 20px 0;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileNavItem = styled.a`
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  font-size: 18px;

  &:hover {
    background-color: #444;
  }
`;

const Header = ({ isDarkBackground }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HeaderContainer isDark={isDarkBackground}>
      <Logo>
        <LogoLink isDark={isDarkBackground} href="#MainPage">
          Tropical Mood
        </LogoLink>
      </Logo>
      {/* <Nav>
        <NavItem href="#MainPage">Home</NavItem>
        <NavItem href="#about">About</NavItem>
        <NavItem href="#services">Services</NavItem>
        <NavItem href="#contact">Contact</NavItem>
      </Nav>
      <Hamburger onClick={() => setIsOpen(!isOpen)}>
        <FaBars />
      </Hamburger>
      <MobileNav open={isOpen}>
        <MobileNavItem href="#MainPage">Home</MobileNavItem>
        <MobileNavItem href="#about">About</MobileNavItem>
        <MobileNavItem href="#services">Services</MobileNavItem>
        <MobileNavItem href="#contact">Contact</MobileNavItem>
      </MobileNav> */}
    </HeaderContainer>
  );
};

export default Header;
