import React from "react";
import styled from "styled-components";
import {
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaCommentDots,
} from "react-icons/fa";

const FooterContainer = styled.footer`
  margin: 0 auto;
  width: 80%;
  display: flex;
  height: 100vh;
  padding: 20px;
  text-align: center;
  color: #333;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px;
    width: auto;
    display: block;
  }
`;

const ContactInfo = styled.div`
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 10px;
    font-size: 14px;
    margin: 0 auto;
    width: 90%;
    margin-top: 50px;
  }
`;

const Infosection = styled.div`
  @media (max-width: 768px) {
    margin: 0 auto;
    width: 90%;
    margin-top: 50px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const SocialIcon = styled.a`
  margin: 0 10px;
  font-size: 24px;
  color: #333;

  &:hover {
    color: #777;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin: 0 5px;
  }
`;

const FooterText = styled.div`
  font-size: 14px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <ContactInfo>
        <h2>고객센터</h2>
        <h3>0507-1320-9329</h3>
        <p>OPEN TIME (10:00 ~ 18:00)</p>
        <p>LUNCH (12:00 ~ 13:00)</p>
        <p>OFF (SATURDAY, SUNDAY, HOLIDAY)</p>
        <p>
          신한은행 140-008-577707
          <b style={{ fontSize: "12px" }}> &nbsp; 김덕기</b>
        </p>
      </ContactInfo>
      <Infosection>
        <SocialIcons>
          <SocialIcon href="#">
            <FaInstagram />
          </SocialIcon>
          <SocialIcon href="#">
            <FaTwitter />
          </SocialIcon>
          <SocialIcon href="#">
            <FaYoutube />
          </SocialIcon>
          <SocialIcon href="#">
            <FaCommentDots />
          </SocialIcon>
        </SocialIcons>
        <FooterText>
          <p>이용안내 | 쇼핑몰 이용약관 | 개인정보처리방침</p>
          <p>커넥트인터내셔널즈 대표: 김덕기 사업자번호: 781-75-00373</p>
          <p>통신판매신고: 제 2024 - 서울송파 - 2396 호 [사업자정보확인]</p>
          <p>주소: 서울특별시 송파구 오금로 58 (잠실 아이 스페이스) 2216호</p>
          <p>Copyright © TROPICAL-MOOD. All rights reserved.</p>
        </FooterText>
      </Infosection>
    </FooterContainer>
  );
};

export default Footer;
