import React, { useState, useEffect } from "react";
import { SectionsContainer, Section } from "react-fullpage";
import styled from "styled-components";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Section1 from "./components/Section-1";
import Section2 from "./components/Section-2";
import Section3 from "./components/Section-3";
import Section4 from "./components/Section-4";

import "./App.css";

const Wrapper = styled.div`
  .Navigation {
    right: -10px !important;
    top: 50%;
    transform: translateY(-50%);
    color: black !important;
  }

  @media (max-width: 768px) {
    .Navigation {
      right: -10px !important;
      transform: translateX(-50%);
      color: white !important;
    }

    .Navigation ul {
      display: flex !important;
      flex-direction: row !important;
    }

    .Navigation ul li {
      margin: 0 5px !important;
    }

    .Navigation ul li a span,
    .Navigation ul li .fp-tooltip {
      display: none !important;
    }

    .Navigation ul li a {
      background: white !important;
      border: 1px solid black !important;
      border-radius: 50% !important;
    }

    .Navigation ul li a.active {
      background: black !important;
      border: 1px solid white !important;
    }
  }
`;

const App = () => {
  const [isDarkBackground, setIsDarkBackground] = useState(false);

  const options = {
    sectionClassName: "section",
    anchors: ["MainPage", "Signature", "Products", "footer"],
    scrollBar: false,
    navigation: true,
    verticalAlign: false,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const section = entry.target;
            if (section.classList.contains("dark-background")) {
              setIsDarkBackground(true);
            } else {
              setIsDarkBackground(false);
            }
          }
        });
      },
      { threshold: 0.7 }
    );

    const sections = document.querySelectorAll(".section");
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  return (
    <div>
      <Header isDarkBackground={isDarkBackground} />
      <Wrapper>
        <SectionsContainer {...options}>
          <Section className="MainPage dark-background">
            <div className="firstPage">
              <Section1 />
            </div>
          </Section>
          <Section className="Signature">
            <div className="secondPage">
              <Section2 />
            </div>
          </Section>
          {/* <Section className="VideoSection dark-background">
            <div className="ThirdPage">
              <Section3 />
            </div>
          </Section> */}
          <Section className="Section4">
            <div className="Section4">
              <Section4 />
            </div>
          </Section>
          <Section className="section footer">
            <div className="footer">
              <Footer />
            </div>
          </Section>
        </SectionsContainer>
      </Wrapper>
    </div>
  );
};

export default App;
